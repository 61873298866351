import { SELECT_MENU } from '../action-types';

const initialState: InitialState = {
  selectedMenu: 'home'
};

export default (state = initialState, action: any) => {
  if (action.type === SELECT_MENU) {
    return {
      ...state,
      selectedMenu: action.payload
    };
  } else {
    return state;
  }
};

interface InitialState {
  selectedMenu: string
}
